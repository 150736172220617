
import Vue from 'vue'
import SiteNavGroupHome from '~/components/site/nav/group/Home.vue'
import LivePoller from '~/components/_general/LivePoller.vue'
import SiteNavGroupSermons from '~/components/site/nav/group/Sermons.vue'
import SiteNavGroupRadio from '~/components/site/nav/group/Radio.vue'
import SiteNavGroupAdmin from '~/components/site/nav/group/Admin.vue'
import SiteNavTemplate from '~/components/site/nav/Template.vue'
import SiteNavBase from '~/components/site/nav/Base.vue'

export enum SiteNavGroup {
  Home,
  Admin,
  Radio,
  Sermons,
  Promotional,
}

export default Vue.extend({
  name: 'SiteNavContents',
  components: {
    SiteNavGroupAdmin,
    SiteNavGroupRadio,
    SiteNavGroupSermons,
    SiteNavBase,
    LivePoller,
    SiteNavGroupHome,
    SiteNavTemplate,
  },
  computed: {
    SiteNavGroup() {
      return SiteNavGroup
    },
    user(): boolean {
      return this.$users.loggedIn
    },
    admin(): boolean {
      return this.$users.user?.admin ?? false
    },
  },
  methods: {
    navigated(navigate: () => void) {
      this.$emit('navigate')
      navigate()
    },
  },
})
